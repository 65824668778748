import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const SiteLayout = React.lazy(() => import('./layout/SiteLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))

const App = () => {
  const currentUser = useSelector((state) => state?.common?.currentUser || "")
  console.log('currentUser', currentUser)
  return (
    <BrowserRouter>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {currentUser ? (
            <>
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </>
          ) : (
            <>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route path="*" name="Site" element={<SiteLayout />} />
            </>
          )}
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
